import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ApplicationLanguages } from '@app/core/configs/app-languages';
import { LangSessionName } from '@app/core/configs/app-session-name';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  title = 'web-godeliverix-client';

  constructor(
    private _translateService: TranslateService,
  ) {
    this._unsubscribeAll = new Subject();

    // Define translations
    this._translateService.addLangs(ApplicationLanguages.map((lang: any) => lang.id));

    // Use a language
    const lang = localStorage.getItem(LangSessionName);
    if (lang) {
      this._translateService.setDefaultLang(lang);
      this._translateService.use(lang);
    } else {
      this._translateService.setDefaultLang('en');
      this._translateService.use('en');
      localStorage.setItem(LangSessionName, 'en');
    }
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
