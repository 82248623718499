export const ApplicationLanguages = [
  {
    id: 'en',
    title: 'English',
    flag: 'us'
  },
  {
    id: 'es',
    title: 'Spanish',
    flag: 'mx'
  }
];
